<template>
  <ul :class="'ul-'+type">
    <li v-for="item in items" :key="item" v-html="item"/>
  </ul>
</template>

<script>
export default {
  name: "custom-list",
  props: {
    items: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'yellow'
    }
  },
}
</script>

<style scoped>
ul {
  padding-left: 0;
}

ul.ul-dash li {
  list-style: none;
  /* margin-bottom: 20px; */
  text-indent: -20px;
  margin-left: 20px;
}

ul.ul-dash ::before {
  display: inline-block;
  position: relative;
  margin-top: -10px;
  width: 10px;
  content: ' ';
  border-top: 2px solid #555555;
  height: 2px;
  margin-right: 10px;
  top: -4px;
}

ul.ul-yellow li {
  list-style: none;
  text-indent: -24px;
  margin-left: 24px;
  line-height: 20px;
  margin-bottom: 10px;
}

ul.ul-yellow li:before {
  content: '●';
  font-size: 20px;
  color: #ffdd2d;
  margin-right: 10px;
}

ul.ul-arrow li {
  list-style: none;
  margin-left: 24px;
  line-height: 20px;
  margin-bottom: 10px;
}

ul.ul-arrow li:before {
  content: "\f0d9";
  font-size: 20px;
  color: #888;
  margin-right: 10px;
  transform: rotate(45deg);
  display: inline-block;
  position: absolute;
  margin-left: -24px;
  margin-top: -5px;
  font-family: 'Font Awesome';
  font-weight: 900;
}

</style>