<template>
  <iframe width="100%" height="680" :src="returnUrl" scrolling="no" id="form-iframe"/>
</template>

<script>
export default {
  name: "app-form",
  props: ['url', 'tiktokToken', 'tiktokEvents'],
  computed: {
    returnUrl: function () {
      return this.url + '?parent_url=' + encodeURIComponent(document.location.href);
    }
  },
  created: function () {
    window.dataLayer = window.dataLayer || [];

    window.addEventListener("message", function (event) {
      if (typeof event.data !== 'string') {
        return;
      }
      var evdata = JSON.parse(event.data);

      if (evdata.type === "appFramePageHeight") {
        var h = evdata.result.height;
        document.getElementById('form-iframe').style.height = (h + 30) + "px";
      }
      else if (evdata.type === 'analytics') {
        // eslint-disable-next-line no-empty
        if (evdata.event === 'formSubmit') {
          window.dataLayer.push({'event': 'conversion'});
        }
      }

    }, false);
  }
}

</script>

<style scoped>
iframe {
  border: none;
  overflow: hidden;
}
</style>