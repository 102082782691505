<template>
  <site-header :phone="phone"></site-header>
  <gradient-delimiter height="32px" start-color="#e6e6e7" end-color="#f6f7f8"/>
  <div class="bg-[#f6f7f8]">
    <div class="container">
      <h1 class="pt-[76px] md:pb-6 pb-2 md:w-2/3 md:text-[44px] text-[24px] font-bold mx-auto text-center">Премиальный уровень с картой Tinkoff Black Premium</h1>
      <p class="font-normal md:text-[15px] text-[13px] mx-auto text-center ">Пользуйтесь картой в 9 странах мира. Получайте привилегии в банке, дома и в поездках</p>
      <yellow-button class="mx-auto block mt-10" @click="gotoForm">Стать премиум-клиентом</yellow-button>
      <div class="mt-16 md:pb-12 pb-5 flex relative h-auto md:h-[480px] md:max-w-full justify-center overflow-hidden">
        <img src="@/assets/images/top.png" alt="top" class="">
        <div class="shadow absolute md:h-[45px] h-0 w-full bottom-0"></div>
      </div>

      <h2 class="md:pt-[76px] pt-12 md:pb-6 pb-2 text-[24px] md:text-[44px] md:max-w-[75%] font-bold mx-auto text-center">Эксклюзивная металлическая карта</h2>
      <p class="full font-normal md:text-[15px] text-[13px] mx-auto text-center md:max-w-[70%] pb-6">Выпускается ограниченными партиями. Стоимость выдачи карты — 1990 ₽ или бесплатно при выполнении <a href="" class="text-[#336fee] hover:text-[#244ca2]">условий обслуживания</a></p>
      <p class="mobil font-normal md:text-[15px] text-[13px] mx-auto text-center md:max-w-[70%] pb-6">Стоимость выдачи — 1990 ₽ при отключении Premium в первые 3 месяца</p>
      <div class="full py-6 box-border">
        <div class="py-6">
          <div class="md:grid grid-cols-3 gap-12">
            <div class="bg-white rounded-3xl p-8 text-center mb-10 md:mb-0">
              <div class="w-[56px] h-[56px] bg-[#333333] rounded-2xl flex items-center justify-center text-2xl text-white mx-auto -mt-14 mb-8">1</div>
              <div class="md:text-base text-[13px]"><p>Оформите премиальную карту и получите с доставкой, куда удобно в <a href="" class="text-[#336fee] hover:text-[#244ca2]">Москве</a></p></div>
            </div>
            <div class="bg-white rounded-3xl p-8 text-center mb-10 md:mb-0">
              <div class="w-[56px] h-[56px] bg-[#333333] rounded-2xl flex items-center justify-center text-2xl text-white mx-auto -mt-14 mb-8">2</div>
              <div class="md:text-base text-[13px]"><p>Пользуйтесь премиум-сервисами уже сегодня — в приложении Тинькофф</p></div>
            </div>
            <div class="bg-white rounded-3xl p-8 text-center mb-10 md:mb-0">
              <div class="w-[56px] h-[56px] bg-[#4972cf] rounded-2xl flex items-center justify-center text-2xl text-white mx-auto -mt-14 mb-8">3</div>
              <div class="md:text-base text-[13px]"><p>Совершайте покупки с повышенным кэшбэком</p></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobil py-3 box-border overflow-hidden w-full">
        <div class="mb-6 ml-7 relative">
          <div class="-left-[28px] absolute top-0">
            <div class="w-[56px] h-[56px] bg-[#333333] text-white rounded-2xl flex items-center justify-center text-2xl">1</div>
          </div>
          <div class="bg-white rounded-3xl p-6 pl-[52px]">
            <div class="text-base"><p>Оформите премиальную карту и получите с доставкой, куда удобно в <a href="" class="text-[#336fee] hover:text-[#244ca2]">Москве</a></p></div>
          </div>
        </div>
        <div class="mb-6 ml-7 relative">
          <div class="-left-[28px] absolute top-0">
            <div class="w-[56px] h-[56px] bg-[#333333] text-white rounded-2xl flex items-center justify-center text-2xl">2</div>
          </div>
          <div class="bg-white rounded-3xl p-6 pl-[52px]">
            <div class="text-base"><p>Пользуйтесь премиум-сервисами уже сегодня — в приложении Тинькофф</p></div>
          </div>
        </div>
        <div class="mb-6 ml-7 relative">
          <div class="-left-[28px] absolute top-0">
            <div class="w-[56px] h-[56px] bg-[#4972cf] text-white rounded-2xl flex items-center justify-center text-2xl">3</div>
          </div>
          <div class="bg-white rounded-3xl p-6 pl-[52px]">
            <div class="text-base"><p>Совершайте покупки с повышенным кэшбэком</p></div>
          </div>
        </div>
      </div>
      
    </div>

    <app-form url="https://www.tinkoff.ru/tinkoff-premium/cards/debit-cards/tinkoff-black-premium/iframe/form/" class="py-6"/>

    <div class="container">
      <h2 class="md:pt-[76px] pt-12 md:pb-6 pb-2 text-[24px] md:text-[44px] md:max-w-[50%] font-bold mx-auto text-center">Преимущества карты Tinkoff Black Premium</h2>
      <div class="full md:grid grid-cols-2 gap-12 py-6">
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-white h-full rounded-3xl relative">
            <div class="flex flex-col box-border py-8 px-8 relative justify-between">
              <div><h3 class="mb-4 md:font-medium font-bold md:text-2xl text-[17px]">Действует в 9 странах</h3>
                <div class="font-normal md:text-base text-[13px]"><p>Абхазии, Армении, Беларуси, Вьетнаме, Казахстане, Кыргызстане, Таджикистане, Южной Корее и Южной Осетии</p></div>
              </div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-white h-full rounded-3xl relative">
            <div class="flex flex-col box-border py-8 px-8 relative justify-between">
              <div><h3 class="mb-4 md:font-medium font-bold md:text-2xl text-[17px]">Снятие наличных без комиссии в России</h3>
                <div class="font-normal md:text-base text-[13px] pb-2"><p>Бесплатно на любую сумму в рублях в банкоматах Тинькофф и до 500 000 ₽ в месяц у партнеров</p></div>
                <div class="font-normal md:text-base text-[13px] pt-2"><p>До 10 000 $ в период до 9 марта 2023 года</p></div>
              </div>                
            </div> 
          </div>
        </div>
      </div>
      <div class="full flex flex-row-reverse items-start justify-between py-6">
        <div class="flex justify-center relative col-span-2">
          <div class="bg-[#eaecee] rounded-3xl overflow-hidden flex justify-center md:h-[560px] md:w-[720px] h-[320px]">
            <img src="@/assets/images/list_1.png" alt="cre">
          </div>
        </div>
        <div class="pt-8 w-[336px]">
          <div>
            <h3 class="mb-4">Повышенные лимиты на снятие наличных</h3>
            <custom-list class="pb-1.5 text-base font-normal" type="dash" :items="['До 10 000 $ или € в месяц в странах, где принимают карту «Мир»']"/>
            <custom-list class="pt-1.5 text-base font-normal" type="dash" :items="['Бесплатно на любую сумму в рублях в банкоматах Тинькофф и до 500 000 ₽ в месяц у партнеров']"/>
          </div>
          <div class="mt-12">
            <h3 class="mb-4">Переводы без комиссии</h3>
            <custom-list class="pb-1.5 text-base font-normal" type="dash" :items="['Бесплатные переводы внутри Тинькофф на любую сумму']"/>
            <custom-list class="py-1.5 text-base font-normal" type="dash" :items="['В другие банки — до 100 000 ₽ без комиссии, включая трансграничные переводы']"/>
          </div>          
        </div>
      </div>
      <div class="mobil">
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#eaecee] h-full rounded-3xl relative">
            <div class="flex flex-col box-border p-6 relative justify-between">
              <div><h3 class="mb-2 font-bold text-[17px]">Действует в 9 странах</h3>
                <div class="font-normal md:text-base"><p>Абхазии, Армении, Беларуси, Вьетнаме, Казахстане, Кыргызстане, Таджикистане, Южной Корее и Южной Осетии</p></div>
              </div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#eaecee] h-full rounded-3xl relative">
            <div class="flex flex-col box-border p-6 relative justify-between">
              <div><h3 class="mb-2 font-bold text-[17px]">Снятие наличных без комиссии в России</h3>
                <div class="font-normal md:text-base"><p>Бесплатно на любую сумму в рублях в банкоматах Тинькофф и до 500 000 ₽ в месяц у партнеров</p></div>
              </div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#eaecee] h-full rounded-3xl relative">
            <div class="flex flex-col box-border p-6 relative justify-between">
              <div><h3 class="mb-2 font-bold text-[17px]">Переводы без комиссии</h3>
                <div class="font-normal md:text-base"><p>Бесплатные переводы внутри Тинькофф на любую сумму, в другие банки — до 100 тыс. ₽, включая трансграничные</p></div>
              </div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#eaecee] h-full rounded-3xl relative">
            <div class="flex flex-col box-border p-6 relative justify-between">
              <div><h3 class="mb-2 font-bold text-[17px]">Снятие денег за границей</h3>
                <div class="font-normal md:text-base"><p>До 10 000 $ или 10 000 € в месяц в странах, где принимают карту «Мир»</p></div>
              </div>                
            </div> 
          </div>
        </div>
        <div class="box-border overflow-hidden py-3 px-6">
          <div class="flex justify-center relative">
            <div class="bg-[#eaecee] h-[320px] max-w-[100vw] overflow-hidden rounded-3xl flex justify-center">
              <img src="@/assets/images/mobil_img_1.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="mobil py-3">
        <yellow-button class="mx-auto block" @click="gotoForm">Стать премиум-клиентом</yellow-button>
      </div>

      <h2 class="md:pt-[76px] pt-12 md:pb-6 pb-2 text-[24px] md:text-[44px] md:max-w-[50%] font-bold mx-auto text-center">Защита вкладов, больше дохода и привилегий</h2>
      <div class="full md:grid grid-cols-3 gap-12 py-6">
        <div class="flex justify-center relative col-span-2">
          <div class="bg-white rounded-3xl overflow-hidden flex justify-center md:max-w-full max-w-[100vw] md:h-full h-[320px]">
            <img src="@/assets/images/list_2.png" alt="cre">
          </div>
        </div>
        <div class="pt-8 w-[336px]">
          <div>
            <h3 class="mb-4">Доверьте нам свой капитал</h3>
            <custom-list class="pb-1.5 text-base font-normal" type="dash" :items="['Диверсификация защищает вложения']"/>
          </div>
          <div class="mt-12">
            <h3 class="mb-4">Зарабатывайте деньги, а не бонусы</h3>
            <custom-list class="pb-1.5 text-base font-normal" type="dash" :items="['Краткосрочные вклады с повышенным доходом до 8,64%']"/>
            <custom-list class="py-1.5 text-base font-normal" type="dash" :items="['7% годовых по накопительному счету']"/>
            <custom-list class="py-1.5 text-base font-normal" type="dash" :items="['6% на остаток до 300 000 ₽ и 3% на остальную сумму на карте']"/>
          </div>          
        </div>
      </div>
      <div class="full md:grid grid-cols-3 gap-12 py-6">
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-white h-full rounded-3xl relative">
            <div class="flex flex-col box-border py-8 px-8 relative justify-between">
              <div><h3 class="mb-4 md:font-medium font-bold md:text-2xl text-[17px]">Кэшбэк с покупок — до 60 000 ₽ в месяц</h3>
                <div class="font-normal md:text-[15px] text-[13px]"><p>До 30 000 ₽ в месяц — от банка, еще до 30 000 ₽ — от партнеров по спецпредложениям</p></div>
              </div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-white h-full rounded-3xl relative">
            <div class="flex flex-col box-border py-8 px-8 relative justify-between">
              <div><h3 class="mb-4 md:font-medium font-bold md:text-2xl text-[17px]">Инвестиции Премиум</h3>
                <div class="font-normal md:text-[15px] text-[13px]"><p>Внебиржевые инструменты, комиссия от 0,025% за сделку, круглосуточные инвестконсультации</p></div>
              </div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-white h-full rounded-3xl relative">
            <div class="flex flex-col box-border py-8 px-8 relative justify-between">
              <div><h3 class="mb-4 font-bold text-[17px]">Доставка наличных</h3>
                <div class="font-normal md:text-[15px] text-[13px]"><p>Бесплатно и безопасно, когда и куда вам удобно по Москве и области, а также по Санкт-Петербургу</p></div>
              </div>                
            </div> 
          </div>
        </div>
      </div>
      <div class="mobil overflow-hidden h-auto">
        <Splide :options="{ rewind: true }" aria-label="" >
          <SplideSlide class="justify-between px-4 box-border min-w-full">
            <div class="bg-white h-full rounded-3xl relative">
              <div class="flex flex-col box-border p-6 relative justify-between">
                <div><h3 class="mb-2 font-bold text-[17px]">Зарабатывайте деньги, а не бонусы</h3>
                  <div class="font-normal text-[15px]"><p>Краткосрочные вклады с повышенным доходом до 8,64%</p></div>
                </div>                
              </div> 
            </div>
          </SplideSlide>
          <SplideSlide class="justify-between px-4 box-border min-w-full">
            <div class="bg-white h-full rounded-3xl relative">
              <div class="flex flex-col box-border p-6 relative justify-between">
                <div><h3 class="mb-2 md:font-medium font-bold md:text-2xl text-[17px]">Кэшбэк с покупок — до 60 000 ₽ в месяц</h3>
                  <div class="font-normal text-[15px]"><p>До 30 000 ₽ в месяц — от банка, еще до 30 000 ₽ — от партнеров по спецпредложениям</p></div>
                </div>                
              </div> 
            </div>
          </SplideSlide>
          <SplideSlide class="justify-between px-4 box-border min-w-full">
            <div class="bg-white h-full rounded-3xl relative">
              <div class="flex flex-col box-border p-6 relative justify-between">
                <div><h3 class="mb-2 md:font-medium font-bold md:text-2xl text-[17px]">Доставка наличных</h3>
                  <div class="font-normal text-[15px]"><p>Бесплатно и безопасно, когда и куда вам удобно по Москве и области, а также по Санкт-Петербургу</p></div>
                </div>                
              </div> 
            </div>
          </SplideSlide>
          <SplideSlide class="justify-between px-4 box-border min-w-full">
            <div class="bg-white h-full rounded-3xl relative">
              <div class="flex flex-col box-border p-6 relative justify-between">
                <div><h3 class="mb-2 md:font-medium font-bold md:text-2xl text-[17px]">Инвестиции Премиум</h3>
                  <div class="font-normal text-[15px]"><p>Лучшее приложение для инвестиций, внебиржевые инструменты, комиссия от 0,025% за сделку, инвестконсультации</p></div>
                </div>                
              </div> 
            </div>
          </SplideSlide>
          <SplideSlide class="justify-between px-4 box-border min-w-full">
            <div class="bg-white h-full rounded-3xl relative">
              <div class="flex flex-col box-border p-6 relative justify-between">
                <div><h3 class="mb-2 md:font-medium font-bold md:text-2xl text-[17px]">Доверьте нам свой капитал</h3>
                  <div class="font-normal text-[15px]"><p>Диверсификация защищает вложения</p></div>
                </div>                
              </div> 
            </div>
          </SplideSlide>
          <SplideSlide class="justify-between px-4 box-border min-w-full">
            <div class="bg-white h-full rounded-3xl relative">
              <div class="flex flex-col box-border p-6 relative justify-between">
                <div><h3 class="mb-2 md:font-medium font-bold md:text-2xl text-[17px]">Первоклассное банковское обслуживание </h3>
                  <div class="font-normal text-[15px]"><p>Лучший цифровой банк России для премиальных клиентов в 2022 году по итогам Frank Premium Banking Award 2022</p></div>
                </div>                
              </div> 
            </div>
          </SplideSlide>
        </Splide>
      </div>

      <h2 class="full pt-[76px] md:pb-6 pb-2 text-[24px] md:text-[44px] md:max-w-[50%] font-bold mx-auto text-center">Удобство дома и в поездках</h2>
      <div class="full md:grid grid-cols-2 gap-12 py-6">
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#EAECEE] h-full rounded-3xl relative">
            <div class="flex flex-col box-border py-8 px-8 relative justify-between">
              <div><h3 class="mb-4 md:font-medium font-bold md:text-2xl text-[17px]">Доступ в бизнес-залы по всему миру</h3>
                <div class="font-normal md:text-base text-[13px]"><p>Комфортные лаундж-зоны с едой и напитками. Компенсируем от 2 проходов в месяц</p></div>
              </div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#EAECEE] h-full rounded-3xl relative">
            <div class="flex flex-col box-border py-8 px-8 relative justify-between">
              <div><h3 class="mb-4 md:font-medium font-bold md:text-2xl text-[17px]">30 валют на одной карте</h3>
                <div class="font-normal md:text-base text-[13px]"><p>С бесплатным обслуживанием валютных счетов</p></div>
              </div>                
            </div> 
          </div>
        </div>
      </div>
      <yellow-button class="full mx-auto block mt-10" @click="gotoForm">Получать больше выгоды</yellow-button>
      <h2 class="mobil md:pt-[76px] pt-12 md:pb-6 pb-2 text-[24px] md:text-[44px] font-bold mx-auto text-center">Управление финансами со смартфона</h2>
      <div class="mobil">
        <div class="box-border overflow-hidden py-3 px-6">
          <div class="flex justify-center relative">
            <div class="bg-[#eaecee] h-[320px] max-w-[100vw] overflow-hidden rounded-3xl flex justify-center">
              <img src="@/assets/images/mobil_img_2.png" alt="">
            </div>
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#eaecee] rounded-3xl relative h-full w-full">
            <div class="flex relative p-6 box-border flex-col h-full">
              <div class="w-9 h-9 flex relative mb-6"><img class="w-full" src="@/assets/images/mobil_img_3.svg" alt="cre-IP"></div>
              <div class="inline whitespace-nowrap"><h3 class="md:font-medium font-bold md:text-2xl text-[17px]">Первоклассное приложение банка</h3></div>
              <div class="mt-3 md:text-[15px] text-[13px]">
                <p>Пополнение с других карт, поиск банкоматов с наличными, покупки и бронирования</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#eaecee] h-full rounded-3xl relative">
            <div class="flex flex-col box-border p-6 relative justify-between">
              <div><h3 class="mb-2 font-bold text-[17px]">Доступ в бизнес-залы по всему миру</h3>
                <div class="font-normal md:text-base"><p>Комфортные лаундж-зоны с едой и напитками. Компенсируем от 2 проходов в месяц</p></div>
              </div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#eaecee] h-full rounded-3xl relative">
            <div class="flex flex-col box-border p-6 relative justify-between">
              <div><h3 class="mb-2 font-bold text-[17px]">Обслуживание карты</h3>
                <div class="font-normal md:text-base"><p>0 ₽ в рамках сервиса Tinkoff Premium для всех ваших карт Тинькофф</p></div>
              </div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#eaecee] h-full rounded-3xl relative">
            <div class="flex flex-col box-border p-6 relative justify-between">
              <div><h3 class="mb-2 font-bold text-[17px]">30 валют на карте</h3>
                <div class="font-normal md:text-base"><p>Валютные счета бесплатно. Кэшбэк за покупки в любой валюте</p></div>
              </div>                
            </div> 
          </div>
        </div>
      </div>

      <h2 class="full pt-[76px] md:pb-6 pb-2 text-[24px] md:text-[44px] md:max-w-[50%] font-bold mx-auto text-center">Новый уровень комфорта</h2>
      <div class="full py-6 justify-center">
        <div class="md:grid grid-cols-3 gap-12">
          <div class="box-border max-w-full w-full md:py-0 py-3">
            <div class="bg-white rounded-3xl relative h-full w-full">
              <div class="flex relative p-8 box-border flex-col h-full">
                <div class="w-9 h-9 flex relative mb-11"><img class="w-full" src="@/assets/images/img_1.svg" alt="cre-IP"></div>
                <div class=""><h3 class="md:font-medium font-bold md:text-2xl text-[17px]">Выделенная линия поддержки</h3></div>
                  <div class="mt-3 md:text-[15px] text-[13px]"><p>Повышенная конфиденциальность, помощь в проведении операций, заказ документов</p></div>
              </div>
            </div>
          </div>
          <div class="box-border max-w-full w-full md:py-0 py-3">
            <div class="bg-white rounded-3xl relative h-full w-full">
              <div class="flex relative p-8 box-border flex-col h-full">
                <div class="w-9 h-9 flex relative mb-11"><img class="w-full" src="@/assets/images/img_2.svg" alt="cre-IP"></div>
                <div class=""><h3 class="md:font-medium font-bold md:text-2xl text-[17px]">Бонусы в сервисах Тинькофф</h3></div>
                  <div class="mt-3 md:text-[15px] text-[13px]"><p>Больше кэшбэка за билеты на спортивные матчи и концерты, в кино и театры</p></div>
              </div>
            </div>
          </div>
          <div class="box-border max-w-full w-full md:py-0 py-3">
            <div class="bg-white rounded-3xl relative h-full w-full">
              <div class="flex relative p-8 box-border flex-col h-full">
                <div class="w-9 h-9 flex relative mb-11"><img class="w-full" src="@/assets/images/img_3.svg" alt="cre-IP"></div>
                <div class=""><h3 class="md:font-medium font-bold md:text-2xl text-[17px]">Консьерж-служба</h3></div>
                  <div class="mt-3 md:text-[15px] text-[13px]"><p>Помощь с авторемонтом, бронь услуг, поиск подарков и редких товаров, подбор персонала</p></div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <div class="full md:grid grid-cols-2 gap-12 py-6">
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-white h-full rounded-3xl relative">
            <div class="flex flex-col box-border py-8 px-8 relative justify-between">
              <div><h3 class="mb-4 md:font-medium font-bold md:text-2xl text-[17px]">Первоклассное банковское обслуживание</h3>
                <div class="font-normal md:text-base text-[13px]"><p>Лучший цифровой банк России для премиальных клиентов в 2022 году по итогам Frank Premium Banking Award 2022</p></div>
              </div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-white h-full rounded-3xl relative">
            <div class="flex flex-col box-border py-8 px-8 relative justify-between">
              <div><h3 class="mb-4 md:font-medium font-bold md:text-2xl text-[17px]">Всё в одном приложении</h3>
                <custom-list class="text-base font-normal" type="dash" :items="['Пополнение c других карт, переводы, заказ документов, поддержка в чате 24/7, покупки и бронирования, аналитика расходов']"/>
              </div>                
            </div> 
          </div>
        </div>
      </div>
      <h2 class="mobil md:pt-[76px] pt-12 md:pb-6 pb-2 text-[24px] md:text-[44px] font-bold mx-auto text-center">От надежного банка</h2>
      <div class="mobil overflow-hidden h-auto">
        <Splide :options="{ rewind: true }" aria-label="" >
          <SplideSlide class="px-4">
            <div class="box-border max-w-full w-full md:py-0 py-3">
              <div class="bg-[#eaecee] rounded-3xl relative h-full w-full">
                <div class="flex relative p-6 box-border flex-col h-full items-center justify-center">
                  <div class="bg-white rounded-xl w-12 h-12 flex relative"><img class="w-full" src="@/assets/images/mobil_img_4.svg" alt="cre-IP"></div>
                  <div class="inline pt-6"><h3 class="md:font-medium font-bold md:text-[24px] text-[17px]">Новый уровень комфорта</h3></div>
                  <div class="mt-2 font-normal text-base">
                    <p>Повышенная конфиденциальность, помощь в проведении операций</p>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide class="px-4">
            <div class="box-border max-w-full w-full md:py-0 py-3">
              <div class="bg-[#eaecee] rounded-3xl relative h-full w-full">
                <div class="flex relative p-6 box-border flex-col h-full items-center justify-center">
                  <div class="bg-white rounded-xl w-12 h-12 flex relative"><img class="w-full" src="@/assets/images/mobil_img_5.svg" alt="cre-IP"></div>
                  <div class="inline pt-6"><h3 class="md:font-medium font-bold md:text-[24px] text-[17px]">Бонусы в сервисах Тинькофф</h3></div>
                  <div class="mt-2 font-normal text-base">
                    <p>Больше кэшбэка за билеты на матчи и концерты, в кино и театры</p>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide class="px-4">
            <div class="box-border max-w-full w-full md:py-0 py-3">
              <div class="bg-[#eaecee] rounded-3xl relative h-full w-full">
                <div class="flex relative p-6 box-border flex-col h-full items-center justify-center">
                  <div class="bg-white rounded-xl w-12 h-12 flex relative"><img class="w-full" src="@/assets/images/mobil_img_6.svg" alt="cre-IP"></div>
                  <div class="inline pt-6"><h3 class="md:font-medium font-bold md:text-[24px] text-[17px]">Консьерж-служба</h3></div>
                  <div class="mt-2 font-normal text-base">
                    <p>Помощь с авторемонтом, поиск подарков, подбор персонала</p>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div>

      <h2 class="md:pt-[76px] pt-12 md:pb-6 pb-2 text-[24px] md:text-[44px] font-bold mx-auto text-center">От 0 ₽ за премиальное обслуживание</h2>
      <div class="md:grid grid-cols-3 gap-12 md:py-6">
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#EAECEE] h-full rounded-3xl relative">
            <div class="box-border md:p-8 p-6 relative justify-between">
                <div class="font-normal text-base"><p>Бесплатно при сумме покупок от 200 000 ₽ в месяц и балансе на счетах от 1 млн рублей</p></div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#EAECEE] h-full rounded-3xl relative">
            <div class="box-border md:p-8 p-6 relative justify-between">
                <div class="font-normal text-base"><p>Бесплатно, если общий остаток на счетах от 3 млн рублей</p></div>                
            </div> 
          </div>
        </div>
        <div class="box-border max-w-full w-full md:py-0 py-3">
          <div class="bg-[#EAECEE] h-full rounded-3xl relative">
            <div class="box-border md:p-8 p-6 relative justify-between">
                <div class="font-normal text-base"><p>В прочих случаях — <b>1990 ₽</b> в месяц</p></div>                
            </div> 
          </div>
        </div>
      </div>
      <div class="md:pb-[152px] pb-11">
        <yellow-button class="mx-auto block my-10" @click="gotoForm">Стать премиум-клиентом</yellow-button>
      </div>
    </div>
  </div>
  <site-footer :phone="phone" disclaimer="" copyright=""></site-footer>
</template>

<script>

import '@/assets/tailwind.css';
import SiteHeader from "@/components/site-header";
import GradientDelimiter from "@/components/gradient-delimiter";
import SiteFooter from "@/components/site-footer-v2";
import YellowButton from '@/components/yellow-button.vue';
import AppForm from './components/app-form.vue';
import CustomList from '@/components/custom-list.vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

export default {
  name: 'App',
  components: {GradientDelimiter, SiteHeader, SiteFooter, YellowButton, AppForm, CustomList, Splide, SplideSlide},

  data: () => ({
    phone: process.env.VUE_APP_PHONE,
  }),

  methods: {
    gotoForm() {
      window.scrollTo({
        top: document.getElementById('form-iframe').offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }
}

</script>

<style lang="scss">

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans.woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'haas';
  src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("assets/fonts/fa-regular-400.eot");
  src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("assets/fonts/fa-solid-900.eot");
  src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 15px;
  font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  color: #333333;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
}

.container {
  @media screen and (max-width: 575px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

h1, h2, h3 {
  font-family: 'TinkoffSans', sans-serif;
  font-weight: 400;
}

h1, h2 {
  font-weight: 700;
  font-variant-ligatures: no-contextual;
  font-size: 44px;
  line-height: 48px;
  letter-spacing: 0;
  @media screen and (max-width: 575px) {
    font-size: 32px;
    line-height: 35px;
  }
}

h2 {
  @media screen and (max-width: 575px) {
    font-size: 28px;
    line-height: 32px;
  }
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

.header {
  @apply mt-24 w-2/3 mx-auto text-center;
}

::selection {
  background: rgba(255, 221, 45, .32) !important;
  color: rgba(0, 0, 0, .8) !important;
}

.numbers {
  font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 100;
}

.shadow {
  box-shadow: 0 0 50px 60px #f6f6f6;
  content: "";
}

.scale:hover {
  transform: scale(1.02, 1.02);
  transition: .4s ease-in-out;
}

.scale:active {
  transform: scale(1, 1);
  transition: .4s ease-in-out;
}

@media screen and (max-width: 1100px) {
  .full {
    display: none;
  }
}

@media screen and (min-width: 1100px) {
  .mobil {
    display: none;
  }
}

</style>
